import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Hi, I'm Edward Woodcock"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <h1>Edward Woodcock</h1>
        <p>Founder, product maker and music producer. I like to solve widespread problems simply.</p>

        <div class="p-3 grid gap-10 grid-cols-1 md:grid-cols-2">

          <div>
            <h2>Work</h2>
            <ul>
              <li><a href="https://dinghy.us/" target="_blank" rel="noopener noreferrer">Dinghy USA</a>, <em>CTO & Co-founder</em></li>
              <li><a href="https://getdinghy.com/" target="_blank" rel="noopener noreferrer">Dinghy</a>, <em>CTO & Co-founder</em></li>
              <li>ECAW, <em>Technical Consultant</em></li>
              <li>Simple Merchant, <em>CTO</em></li>
              <li>Simply Business, <em>Software Developer</em></li>
            </ul>

            <h2>Study</h2>
            <ul>
              <li>MEng, Computer Science <em>University of Bristol</em></li>
              <li>Master Diploma Music Production (in prog.), <em>Point Blank Music School</em></li>
            </ul>
          </div>

          <div>
            <h2>Press</h2>
            <ul>
              <li><a href="https://www.forbes.com/sites/alisoncoleman/2019/07/09/four-insurtech-startups-shaking-up-the-insurance-industry/" target="_blank" rel="noopener noreferrer">Information Age, 2019</a>: Four Insurtech Startups Shaking Up The Insurance Industry</li>
              <li><a href="https://www.information-age.com/lean-startup-for-tech-entrepreneurs-123483754/" target="_blank" rel="noopener noreferrer">Information Age, 2019</a>: Lean startup for tech entrepreneurs</li>
              <li><a href="https://techcrunch.com/2018/01/05/dinghy/" target="_blank" rel="noopener noreferrer">TechCrunch, 2018</a>: Dinghy picks up backing from Balderton for its flexible freelancer insurance</li>
            </ul>

            <h2>Contact</h2>
            <p>Find me on <a href="https://instagram.com/remote.cto" target="_blank" rel="noopener noreferrer">Instagram</a> and <a href="https://twitter.com/remotecto" target="_blank" rel="noopener noreferrer">Twitter</a></p>
          </div>

        </div>
      </Layout>
    )
  }
}

export default IndexPage
